export default {
  black_and_white: {
    font_light: "#FFFFFC",
    font_heavy: "#000000",
    background_light: "#FFFFFF",
    background_heavy: "#000000",
    background_heavy_RGB: "0, 0, 0",
    body_color_RGB: "255, 255, 255",
    item_name_color: "#FFFFFF",
    body_color: "#FFFFFF",
  },
  pink_lady: {
    font_light: "#FDCFF3",
    font_heavy: "#bd5696",
    background_light: "#cdd0d8",
    background_heavy: "#070707",
    background_heavy_RGB: "7, 7, 7",
    body_color_RGB: "231, 234, 241",
    item_name_color: "#FDCFF3",
    body_color: "#e7eaf1",
  },
  shadow_rainforest: {
    font_light: "#A1E887",
    font_heavy: "#427f88",
    background_light: "#bdfbd4",
    background_heavy: "#493548",
    background_heavy_RGB: "73, 53, 72",
    body_color_RGB: "255, 255, 255",
    item_name_color: "#A1E887",
    body_color: "#ffffff",
  },
  dark_neon: {
    font_light: "#0291CA",
    font_heavy: "#FFFEFF",
    background_light: "#FF01FB",
    background_heavy: "#FAFF00",
    background_heavy_RGB: "250, 255, 0",
    body_color_RGB: "0, 3, 0",
    item_name_color: "#000300",
    body_color: "#000300",
  },
  good_morning: {
    font_light: "#F4D35E",
    font_heavy: "#F95738",
    background_light: "#EBEBD3",
    background_heavy: "#083D77",
    background_heavy_RGB: "8, 61, 119",
    body_color_RGB: "255, 255, 255",
    item_name_color: "#F4D35E",
    body_color: "#ffffff",
  },
  mystic_forest: {
    font_light: "#9ecfe2",
    font_heavy: "#627C85",
    background_light: "#A2E8DD",
    background_heavy: "#35524A",
    background_heavy_RGB: "53, 82, 74",
    body_color_RGB: "255, 255, 255",
    item_name_color: "#A2E8DD",
    body_color: "#ffffff",
  },
  navy: {
    font_light: "#F4FFFE",
    font_heavy: "#09004C",
    background_light: "#F8F8FF",
    background_heavy: "#030553",
    background_heavy_RGB: "3, 5, 83",
    body_color_RGB: "255, 252, 253",
    item_name_color: "#FFFFFF",
    body_color: "#FFFCFD",
  }
}